@font-face {
  font-family: "Bahnschrift";
  src: url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.eot"), /* IE9*/
       url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.woff2") format("woff2"), /* chrome firefox */
       url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.woff") format("woff"), /* chrome firefox */
       url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
       url("https://db.onlinewebfonts.com/t/0a6ee448d1bd65c56f6cf256a7c6f20a.svg#Bahnschrift") format("svg"); /* iOS 4.1- */
}

body {
  -ms-overflow-style: none;
  height: 100%;
  width: 100vw;
  overflow: auto;
  min-height: 100vh;
  margin: 0;
  font-family: "Bahnschrift", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 60px 0 0 0;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  overflow: -moz-hidden-unscrollable;
  height: 100%;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  body {
    padding: 56px 0 0 0;
  }
}

